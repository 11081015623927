import React from 'react'
import { ReactSVG } from 'react-svg'
import svg_fb from '../../images/icons-and-logo/social-fb.svg'
import svg_tk from '../../images/icons-and-logo/social-tiktok.svg'
import './detail.scss'

const SocialMediaDetail = ({ data }) => {
    const renderSocialIcons = (contents) => {
        return contents.map((content) => {
            if (content.sosmed === 'Facebook') {
                return (
                    <div key={content._id} className="socmed-icon">
                        <a
                            className="link-url grow shrink"
                            href={content.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ReactSVG src={svg_fb} />
                            <span className="text-url">
                                Ellips {content.country}
                            </span>
                        </a>
                    </div>
                )
            } else if (content.country_code === 'VN' && content.sosmed === 'Facebook') {
                return (
                    <div key={content._id} className="box-facebook">
                        <div className="socmed-icon">
                            <a
                                className="link-url grow shrink"
                                href={content.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ReactSVG src={svg_tk} />
                                <span className="text-url">
                                    Ellips {content.country}
                                </span>
                            </a>
                        </div>
                        <div className="row">
                            {[1, 2, 3, 4].map((index) => (
                                <div
                                    key={index}
                                    className="col-12 col-sm-6 col-lg-3"
                                >
                                    <div className="box-img">
                                        <img
                                            src={
                                                content[`image_${index}`] || ''
                                            }
                                            alt="social-facebook"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )  
            } else if (content.sosmed === 'Tiktok') {
                return (
                    <div key={content._id} className="socmed-icon">
                        <a
                            className="link-url grow shrink"
                            href={content.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ReactSVG src={svg_tk} />
                            <span className="text-url">
                                Ellips {content.country}
                            </span>
                        </a>
                    </div>
                )
            }
            return null
        })
    }

    const renderInstagramFeeds = (contents) => {
        return contents.map((content) => {
            if (content.sosmed === 'Instagram') {
                return (
                    <div key={content._id} className="box-instagram">
                        <div className="social-wrapper mont-regular">
                            <div className="content-list">
                                <iframe
                                    src={content.url}
                                    width="100%"
                                    frameBorder="0"
                                    className="feed-instagram"
                                    title={content._id}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                )
            }
            return null
        })
    }

    const renderFacebookImages = (contents) => {
        return contents.map((content) => {
            if (content.country_code === 'VN' && content.sosmed === 'Facebook') {
                return (
                    <div key={content._id} className="box-facebook">
                        <div className="row">
                            {[1, 2, 3, 4].map((index) => (
                                <div
                                    key={index}
                                    className="col-12 col-sm-6 col-lg-3"
                                >
                                    <div className="box-img">
                                        <img
                                            src={
                                                content[`image_${index}`] || ''
                                            }
                                            alt="social-facebook"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
            return null
        })
    }

    const renderOtherImages = (contents) => {
        return contents.map((content) => {
            if (content.country_code === 'CN' && content.sosmed === 'Weiboo') {
                return (
                    <div key={content._id} className="box-others">
                        <div className="row">
                            {[1, 2, 3, 4].map((index) => (
                                <div
                                    key={index}
                                    className="col-12 col-sm-6 col-lg-3"
                                >
                                    <div className="box-img">
                                        <img
                                            src={
                                                content[`image_${index}`] || ''
                                            }
                                            alt="social-weiboo"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
            return null
        })
    }

    const groupDataBySosmed = (data) => {
        return data.reduce((acc, content) => {
            if (!acc[content.sosmed]) {
                acc[content.sosmed] = []
            }
            acc[content.sosmed].push(content)
            return acc
        }, {})
    }

    const groupedData = groupDataBySosmed(data)

    return (
        <div className="social-detail-container">
            <div className="box-social">
                <div className="box-list">
                    {groupedData.Facebook && renderSocialIcons(groupedData.Facebook)}
                    {groupedData.Tiktok && renderSocialIcons(groupedData.Tiktok)}
                </div>
                {groupedData.Instagram && renderInstagramFeeds(groupedData.Instagram)}
                {groupedData.Facebook && renderFacebookImages(groupedData.Facebook)}
                {groupedData.Weiboo && renderOtherImages(groupedData.Weiboo)}
            </div>
        </div>
    )
}

export default SocialMediaDetail