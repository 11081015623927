import React, { useState } from 'react'
import { withTrans } from '../../i18n/withTrans'
import { useTranslation } from 'react-i18next'

import MetaTags from '../MetaTags'
import Navigation from '../../components/Navigation'
import Footer from '../../components/Footer'

import './social.scss'
import banner_desktop from '../../images/socmed/banner-socmed-desktop.webp'
import banner_mobile from '../../images/socmed/banner-socmed-mob.webp'
import SocialMediaTabs from '../SocialMediaTabs'
import SocialMediaDetail from '../SocialMediaDetail'

const SocialMedia = ({ pageContext }) => {
    const DataSocial = pageContext.socmedData
    console.log('Cek Data Social', DataSocial)
    const { t } = useTranslation()
    const [data, setData] = useState(
        DataSocial.filter((item) => item.country === 'Bulgaria')
    )
    const [selectedCountry, setSelectedCountry] = useState('Bulgaria')

    const menuCountry = [...new Set(DataSocial.map((Val) => Val.country))]

    const filterSocmed = (country) => {
        if (country) {
            setData(DataSocial.filter((item) => item.country === country))
        } else {
            setData(DataSocial)
        }
        setSelectedCountry(country)
    }

    return (
        <div className="socmed-page">
            <MetaTags
                title="Ellips | Social Media Gallery"
                description={`${t('social.meta-desc')}`}
                image={banner_desktop}
                url={typeof window !== 'undefined' ? window.location.href : ''}
            />
            <Navigation activeLanguage={pageContext.lang} />
            <div className="socmed-page-container">
                <div className="social-container">
                    <img
                        src={banner_desktop}
                        alt="image"
                        className="img-fluid radius-bottom-right d-none d-md-block radius-bottom-right"
                    />
                    <img
                        src={banner_mobile}
                        alt="image"
                        className="img-fluid radius-bottom-right d-block d-md-none radius-bottom-right"
                    />
                    <div className="page-title text-focus-in">
                        <h1>Our Social Media</h1>
                    </div>
                </div>

                <div className="box-content container">
                    <div className="social-title">
                        <h1>Our Social Media</h1>
                    </div>
                    <SocialMediaTabs
                        countries={menuCountry}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        onSelectCountry={filterSocmed}
                    />
                    <SocialMediaDetail data={data} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withTrans(SocialMedia)