import React, { useState } from 'react'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCaretLeft,
    faCaretRight,
} from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import PropTypes from 'prop-types'
import './tab-socmed.scss'

const SocialMediaTabs = ({ countries, selectedCountry, setSelectedCountry, onSelectCountry }) => {
    const renderNavLinks = () => {
        return countries.map((country, index) => (
            <SwiperSlide className="item-slider">
                <Nav.Link
                    key={index}
                    className={`${ selectedCountry === country ? 'active' : '' }`}
                    eventKey={country}
                    onClick={() => onSelectCountry(country)}
                >
                    {country}
                </Nav.Link>
            </SwiperSlide>
        ))
    }

    const renderDropdownItems = () => {
        return countries.map((country, index) => (
            <Dropdown.Item
                key={index}
                onClick={() => {
                    onSelectCountry(country)
                    setSelectedCountry(country) // Set active country when clicked
                }}
                // className={activeCountry === country ? 'active' : ''}
                className={`${selectedCountry === country ? 'active' : ''}`}
            >
                {country}
            </Dropdown.Item>
        ))
    }

    return (
        <div className="menu-country">
            <Navbar bg="none" expand="md" className="d-md-block d-none">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                        className="navigation-box mont-regular"
                        defaultActiveKey="Bulgaria"
                    >
                        <Swiper
                            slidesPerView={5}
                            spaceBetween={20}
                            autoHeight={false}
                            loop={true}
                            navigation={{
                                nextEl: '.arrow-right',
                                prevEl: '.arrow-left',
                            }}
                            pagination={false}
                            breakpoints={{
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                            }}
                            modules={[Navigation]}
                            className="mySwiper"
                        >
                            {renderNavLinks()}
                        </Swiper>
                        <div className="box-navigation">
                            <a
                                className="arrow-left arrow"
                                href="#categoryCarousel"
                                role="button"
                            >
                                <FontAwesomeIcon icon={faCaretLeft} />
                            </a>
                            <a
                                className="arrow-right arrow"
                                href="#categoryCarousel"
                                role="button"
                            >
                                <FontAwesomeIcon icon={faCaretRight} />
                            </a>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Dropdown className="d-md-none d-block">
                <Dropdown.Toggle
                    id="dropdown-basic"
                    className="subcat-list btn-primary__fill-pink"
                >
                    {selectedCountry}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderDropdownItems()}</Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

SocialMediaTabs.propTypes = {
    countries: PropTypes.array.isRequired,
    selectedCountry: PropTypes.string,
    onSelectCountry: PropTypes.func.isRequired,
}

export default SocialMediaTabs
